<template>
  <div class="ms-self-inspection-edit">
    <el-form class="zwx-form edit-form" key="msForm" ref="msForm" :model="msForm" :rules="rules" label-position="right" @submit.native.prevent>
      <div class="condition-row" style="margin-top: 20px">
        <el-form-item label="编码：" prop="codeNo" label-width="110px">
          <el-input class="zwx-input" placeholder="请输入" v-model.trim="msForm.codeNo" style="width:260px!important;" maxlength="20" clearable></el-input>
        </el-form-item>
        <el-form-item label="层级编码：" prop="levelCodeNo" label-width="85px">
          <el-input class="zwx-input" placeholder="请输入" v-model.trim="msForm.levelCodeNo" style="width:260px!important;" maxlength="200" clearable></el-input>
        </el-form-item>
      </div>
      <div class="condition-row">
        <el-form-item label="项目名称：" prop="itemName" label-width="110px">
          <el-input class="zwx-input" placeholder="请输入" style="width:626px!important;" v-model.trim="msForm.itemName" maxlength="200" clearable></el-input>
        </el-form-item>
      </div>
      <div class="condition-row">
        <el-form-item label-width="110px" label="是否标题项：" prop="titleItem" style="margin-right: 0 !important">
          <el-radio-group class="zwx-radio-group" v-model="msForm.titleItem" style="min-width: 78px!important;" @change="titleItemChange">
            <el-radio class="zwx-radio" :label="true">是</el-radio>
            <el-radio class="zwx-radio" :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label-width="110px" label="是否关键项：" prop="keyItem" style="margin-right: 0 !important" :required="titleRequired" v-if="msForm.titleItem==false">
          <el-radio-group class="zwx-radio-group" v-model="msForm.keyItem" style="min-width: 78px!important;">
            <el-radio class="zwx-radio" :label="true">是</el-radio>
            <el-radio class="zwx-radio" :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分值：" prop="score" label-width="60px" :required="titleRequired" v-if="msForm.titleItem==false">
          <el-input class="zwx-input" placeholder="请输入" v-model.trim="msForm.score" @input="$validate.nonnegativeDouble($data['msForm'], 'score', 2, 2)" clearable></el-input>
        </el-form-item>
      </div>
      <div class="condition-row" v-if="msForm.titleItem==false">
        <el-form-item label="自查内容：" prop="itemContent" label-width="110px" :required="titleRequired">
          <el-input class="zwx-input text-input" placeholder="请输入" v-model.trim="msForm.itemContent" maxlength="500" clearable></el-input>
        </el-form-item>
      </div>
      <div class="condition-row" v-if="msForm.titleItem==false">
        <el-form-item label="自查方法：" prop="inspectionMethod" label-width="110px">
          <el-input class="zwx-input text-input" placeholder="请输入" v-model.trim="msForm.inspectionMethod" maxlength="200" clearable></el-input>
        </el-form-item>
      </div>
      <base-headline title="选项"  v-if="msForm.titleItem==false" >
          <el-form-item  prop="dependentSubproject" style="margin-bottom:1px!important;">
          <el-checkbox class="zwx-checkbox" v-model="msForm.dependentSubproject" style="margin-left: 5px">依赖子项</el-checkbox>
        </el-form-item>
      </base-headline>
      <el-table class="zwx-table " style="width: 100%;"  :data="msForm.selfInspectionItemOptionList"
                 key="selfInspectionItemOptionList"  stripe ref="selfInspectionItemOptionList" border  v-if="msForm.titleItem==false" >
                 <el-table-column label="操作" width="70" header-align="center"
                             align="center">
              <template slot-scope="scope">
                <el-form-item :id="'ifEnable'+scope.$index" :prop="'selfInspectionItemOptionList.' + scope.$index + '.ifEnable'"
                              :rules="rules.optionIfEnable">
                    <el-checkbox class="zwx-checkbox" v-model="scope.row.ifEnable" style="margin-left: 5px"></el-checkbox>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="选项" width="100" header-align="center"
                             align="left"> 
              <template slot-scope="scope">
                    {{scope.row.optionValue==1?'符合':scope.row.optionValue==2?'基本符合':scope.row.optionValue==3?'不符合':scope.row.optionValue==4?'合理缺项':null}}
              </template>
            </el-table-column>
            <el-table-column label="分值" width="150" header-align="center"
                             align="center">
              <template slot-scope="scope">
                <el-form-item :id="'score'+scope.$index" :prop="'selfInspectionItemOptionList.' + scope.$index + '.score'"
                              :rules="rules.optionScore">
                    <el-input style="width:100% !important" class="zwx-input" v-model="scope.row.score" :disabled="!scope.row.ifEnable"
                            maxlength="10" @input="$validate.nonnegativeDouble($data['msForm']['selfInspectionItemOptionList'][scope.$index], 'score', 2, 2)"
                            placeholder="请输入" clearable/>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="标准" width="700" header-align="center"
                             align="center">
              <template slot-scope="scope">
                <el-form-item :id="'optionContent'+scope.$index" :prop="'selfInspectionItemOptionList.' + scope.$index + '.optionContent'"
                              :rules="rules.optionContent">
                    <el-input style="width:100% !important" class="zwx-input" v-model="scope.row.optionContent"
                            maxlength="500" :disabled="!scope.row.ifEnable"
                            placeholder="请输入" clearable/>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="子项目满足数" min-width="300" header-align="left"
                             align="left">
              <template slot-scope="scope">
                <el-form-item :id="'subLimit'+scope.$index" :prop="'selfInspectionItemOptionList.' + scope.$index + '.subLimit'" 
                              :rules="rules.subLimit">
                    <el-input style="width:200px !important" class="zwx-input" v-model="scope.row.subLimit" :disabled="!scope.row.ifEnable"
                            maxlength="9" @input="$validate.nonnegativeInteger($data['msForm']['selfInspectionItemOptionList'][scope.$index], 'subLimit')"
                            placeholder="请输入" clearable/>
                </el-form-item>
              </template>
            </el-table-column>
      </el-table>
      <base-headline title="子项目"  v-if="msForm.dependentSubproject==true && msForm.titleItem==false" >
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" style="margin-left: 20px !important" @click="addTable">添加</el-button>
      </base-headline>
      <el-table class="zwx-table " style="width: 100%;"  :data="msForm.selfInspectionItemSubExpList"
                 key="selfInspectionItemSubExpList"  stripe ref="selfInspectionItemSubExpList" border  v-if="msForm.dependentSubproject==true && msForm.titleItem==false">
            <el-table-column label="编码" width="300" header-align="center"
                             align="center">
              <template slot-scope="scope">
                <el-form-item :id="'codeNo'+scope.$index" :prop="'selfInspectionItemSubExpList.' + scope.$index + '.codeNo'"
                              :rules="rules.codeNoTable">
                  <el-input style="width:100% !important" class="zwx-input" v-model="scope.row.codeNo"
                            maxlength="20"
                            placeholder="请输入" clearable/>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="内容" width="700" header-align="center"
                             align="center">
              <template slot-scope="scope">
                <el-form-item :id="'content'+scope.$index" :prop="'selfInspectionItemSubExpList.' + scope.$index + '.content'"
                              :rules="rules.content">
                  <el-input style="width:100% !important" class="zwx-input" v-model="scope.row.content"
                            maxlength="200"
                            placeholder="请输入" clearable/>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="是否启用" width="200" header-align="center"
                             align="center">
              <template slot-scope="scope">
                     <el-form-item :id="'ifEnable'+scope.$index" :prop="'selfInspectionItemSubExpList.' + scope.$index + '.ifEnable'"
                              :rules="rules.ifEnable">
                <el-radio-group class="zwx-radio-group" v-model="scope.row.ifEnable" >
            <el-radio class="zwx-radio" :label="true">是</el-radio>
            <el-radio class="zwx-radio" :label="false">否</el-radio>
          </el-radio-group>
            </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="150" header-align="left"
                             align="left">
              <template slot-scope="scope">
                    <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" type="text"  @click="deleteTable(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
        </el-table>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" style="margin-top: 10px" @click="submitTable">保存</el-button>
    </fixed-footer>
  </div>
</template>

<script>
export default {
  name: 'SelfInspectionItemEdit',
  data() {
    return {
      api: this.$store.state.api,
      type: this.$route.params.type,
      ifSave:false,
      titleRequired:false,
      rid: null,
      msFormOldJson:null,
      msForm: {
        codeNo:null,
        levelCodeNo:null,
        itemName:null,
        titleItem:null,
        keyItem:null,
        score:null,
        dependentSubproject:null,
        itemContent:null,
        inspectionMethod:null,
        matchedStandard:null,
        basicallyMatchedStandard:null,
        unmatchedStandard:null,
        presentationStandard:null,
        selfInspectionItemOptionList:[],
        selfInspectionItemSubExpList:[]
      },
      rules: {
        codeNo: [{ required: true, message: '请输入编码', trigger: ['change', 'blur'] }],
        levelCodeNo: [{ required: true, message: '请输入层级编码', trigger: ['change', 'blur'] }],
        itemName: [{ required: true, message: '请输入项目名称', trigger: ['change', 'blur'] }],
        titleItem:[{ required: true, message: '请选择', trigger: ['change'] }],
        keyItem:[{ required: false, validator: this.optionScoreVal, trigger: ['change', 'blur'] }],
        itemContent:[{ required: false, validator: this.optionScoreVal, trigger: ['change', 'blur'] }],
        score:[{ required: false, validator: this.optionScoreVal, trigger: ['change', 'blur'] }],
        optionScore:[{ required: false, validator: this.optionScoreValidator, trigger: ['change', 'blur'] }],
        optionContent:[{ required: false, validator: this.optionScoreValidator, trigger: ['change', 'blur'] }],
        subLimit:[{ required: false, validator: this.optionScoreValidator2, trigger: ['change', 'blur'] }],
        codeNoTable:[{ required: false, validator: this.optionSubmitVal, trigger: ['change', 'blur'] }],
        content:[{ required: false, validator: this.optionSubmitVal, trigger: ['change', 'blur'] }],
      },
    }
  },
  mounted() {
    // 判断添加 还是编辑
    this.pageRouter()
  },
  watch: {},
  // 路由返回跳转确认
  beforeRouteLeave(to, from, next) {
    let formNew = JSON.stringify(this.msForm)
    if (!this.ifSave) {
      if (formNew !== this.msFormOldJson) {
        this.$confirm('数据还未保存，确定取消操作吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
            .then(() => {
              next()
            })
            .catch(() => {
            })
      } else {
        next()
      }
    } else {
      next()
    }
  },
  created() {},
  methods: {
    submitTable() {
      this.$refs['msForm'].validate((valid) => {
        if (valid) {
            this.msForm.rid =this.rid
            let data ={
                rid:this.msForm.rid,
                codeNo:this.msForm.codeNo,
                levelCodeNo:this.msForm.levelCodeNo,
                itemName:this.msForm.itemName,
                titleItem:this.msForm.titleItem,
                keyItem:this.msForm.keyItem,
                itemContent:this.msForm.itemContent,
                inspectionMethod:this.msForm.inspectionMethod,
                matchedStandard:this.msForm.matchedStandard,
                basicallyMatchedStandard:this.msForm.basicallyMatchedStandard,
                unmatchedStandard:this.msForm.unmatchedStandard,
                presentationStandard:this.msForm.presentationStandard,
                score:this.msForm.score,
                dependentSubproject:this.msForm.dependentSubproject,
                selfInspectionItemOptionList:this.msForm.selfInspectionItemOptionList,
                selfInspectionItemSubExpList:this.msForm.selfInspectionItemSubExpList
                
            }
            this.$emit('loading', true)
            this.$system.postJson(
                this.api + '/oh/classification/saveOrUpdateSelfInspectionItem-1',
                data,
                true,
                true,
                (data) => {
                if (data.type === '00') {
                    this.$system.notify('成功', data.mess, 'success')
                    this.$emit('loading', false)
                    this.ifSave = true
                    this.$router.push({ name: 'SelfInspectionItem', params: {fresh:true} }) 
                } else if (data.type === '99') {
                    this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
                } else {
                    this.$system.notify('错误', data.mess, 'error')
                }
                this.$emit('loading', false)
                },
                (data) => {
                this.$emit('loading', false)
                this.$system.notify('错误', data.mess, 'error')
                }
            )
        } else {
          return false
        }
      })
    },
    addTable(){
        this.msForm.selfInspectionItemSubExpList.push({
            "ifEnable":true,
            "codeNo":null,
            "content":null
        })
    },
    deleteTable(index){
        this.$system.msgbox('', '提示', '是否确定删除？', '确定', '取消', () => {
        this.msForm.selfInspectionItemSubExpList.splice(index, 1)
      })
    },
    /**
     * 页面路由回调
     */
    pageRouter() {
      if (this.type === 'add') {
        this.$emit('breadcrumb', '添加', true) 
        this.msForm.selfInspectionItemOptionList =[]
        this.msForm.selfInspectionItemOptionList.push({ifEnable:true,score:null,optionContent:null,subLimit:null,optionValue:1})
        this.msForm.selfInspectionItemOptionList.push({ifEnable:true,score:null,optionContent:null,subLimit:null,optionValue:2})
        this.msForm.selfInspectionItemOptionList.push({ifEnable:true,score:null,optionContent:null,subLimit:null,optionValue:3})
        this.msForm.selfInspectionItemOptionList.push({ifEnable:true,score:null,optionContent:null,subLimit:null,optionValue:4})
        this.msFormOldJson = JSON.stringify(this.msForm)
      }
      if (this.type === 'edit') {
        this.$emit('breadcrumb', '编辑', true)
        this.rid = this.$route.params.rid
        this.getDetail(this.rid)
      }
    },
    titleItemChange(value){
        this.titleRequired =!value
        if(!value){
          if(this.msForm.selfInspectionItemOptionList.length==0){
            this.msForm.selfInspectionItemOptionList =[]
        this.msForm.selfInspectionItemOptionList.push({ifEnable:true,score:null,optionContent:null,subLimit:null,optionValue:1})
        this.msForm.selfInspectionItemOptionList.push({ifEnable:true,score:null,optionContent:null,subLimit:null,optionValue:2})
        this.msForm.selfInspectionItemOptionList.push({ifEnable:true,score:null,optionContent:null,subLimit:null,optionValue:3})
        this.msForm.selfInspectionItemOptionList.push({ifEnable:true,score:null,optionContent:null,subLimit:null,optionValue:4})
          }
        }
    },
    optionScoreVal(rule, value, callback) {
            if(this.$zwxBase.verifyIsNotBlank(this.msForm.titleItem)){
                if (this.msForm.titleItem) {
                    return callback();
                }
                if(this.$zwxBase.verifyIsNotBlank(value)){
                    return callback();
                }else{
                    return callback(new Error("请输入"));
                }
            }   
        },
    optionScoreValidator(rule, value, callback) {
      let index = rule.field.split('.')[1]
      let ifEnable = this.msForm.selfInspectionItemOptionList[index].ifEnable
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.titleItem) && this.msForm.titleItem) {
          return callback();
      }else if(this.$zwxBase.verifyIsNotBlank(this.msForm.titleItem) && !this.msForm.titleItem){
          if(this.$zwxBase.verifyIsNotBlank(ifEnable) && ifEnable){
              if(this.$zwxBase.verifyIsNotBlank(value)){
                    return callback();
                }else{
                    return callback(new Error("请输入"));
                }
        }
      }
      callback()
    },    
    optionScoreValidator2(rule, value, callback) {
      let index = rule.field.split('.')[1]
      let ifEnable = this.msForm.selfInspectionItemOptionList[index].ifEnable
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.titleItem) && this.msForm.titleItem) {
          return callback();
      }else if(this.$zwxBase.verifyIsNotBlank(this.msForm.titleItem) && !this.msForm.titleItem){
          if(this.$zwxBase.verifyIsNotBlank(ifEnable) && ifEnable){
            if(this.$zwxBase.verifyIsNotBlank(this.msForm.dependentSubproject) && this.msForm.dependentSubproject){
                if(this.$zwxBase.verifyIsNotBlank(value)){
                    return callback();
                }else{
                    return callback(new Error("请输入"));
                }
            }   
        }
      }
      callback()
    },    
    optionSubmitVal(rule, value, callback){
        if(this.$zwxBase.verifyIsNotBlank(this.msForm.titleItem)){
                if (this.msForm.titleItem) {
                    return callback();
                }
                if(this.$zwxBase.verifyIsNotBlank(this.msForm.dependentSubproject)){
                    if(this.$zwxBase.verifyIsNotBlank(value)){
                    return callback();
                }else{
                    return callback(new Error("请输入"));
                }
                }
                return callback();
            }  
    },    
    getDetail(rid){
    let data = {
        rid:rid
      }
      this.$emit('loading', true)
      this.$system.get(this.api + '/oh/classification/getSelfInspectionItemByRid-1', data, true, true, (data) => {
        if (data.type === '00') {
        //   itemExp
        if(this.$zwxBase.verifyIsNotBlank(data.itemExp)){
            this.msForm.codeNo=data.itemExp.codeNo,
            this.msForm.levelCodeNo=data.itemExp.levelCodeNo,
            this.msForm.itemName=data.itemExp.itemName,
            this.msForm.titleItem=data.itemExp.titleItem,
            this.titleItemChange(this.msForm.titleItem)
            this.msForm.keyItem=data.itemExp.keyItem,
            this.msForm.score=data.itemExp.score,
            this.msForm.dependentSubproject=data.itemExp.dependentSubproject,
            this.msForm.itemContent=data.itemExp.itemContent,
            this.msForm.inspectionMethod=data.itemExp.inspectionMethod,
            this.msForm.matchedStandard=data.itemExp.matchedStandard,
            this.msForm.basicallyMatchedStandard=data.itemExp.basicallyMatchedStandard,
            this.msForm.unmatchedStandard=data.itemExp.unmatchedStandard,
            this.msForm.presentationStandard=data.itemExp.presentationStandard,
            this.msForm.selfInspectionItemOptionList =data.itemExp.selfInspectionItemOptionList,
            this.msForm.selfInspectionItemSubExpList =data.itemExp.selfInspectionItemSubExpList
        }
        this.msFormOldJson = JSON.stringify(this.msForm)
          this.$emit('loading', false)
        } else if (data.type === '99') {
          this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
        } else {
          this.$notify.error({ title: '错误', message: data.mess })
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.text-input {
  width: 626px !important;
}
</style>
<style>
</style>

